// import cameraDayUp from '@/assets/map/camera_day_up.svg'
// import cameraDayDown from '@/assets/map/camera_day_down.svg'
// import cameraNightUp from '@/assets/map/camera_night_up.svg'
// import cameraNightDown from '@/assets/map/camera_night_down.svg'

import cameraUp from '@/assets/map/camera_up.svg'
import cameraUpSelected from '@/assets/map/camera_up_selected.svg'
import cameraDown from '@/assets/map/camera_down.svg'
import cameraDownSelected from '@/assets/map/camera_down_selected.svg'

import busDayUp from '@/assets/map/bus_day_up.svg'
import busDayUpSelected from '@/assets/map/bus_day_up_selected.svg'
import busDayDown from '@/assets/map/bus_day_down.svg'
import busDayDownSelected from '@/assets/map/bus_day_down_selected.svg'
import busNightUp from '@/assets/map/bus_night_up.svg'
import busNightUpSelected from '@/assets/map/bus_night_up_selected.svg'
import busNightDown from '@/assets/map/bus_night_down.svg'
import busNightDownSelected from '@/assets/map/bus_night_down_selected.svg'

import lprDayUp from '@/assets/map/lpr_day_up.svg'
import lprDayUpSeleted from '@/assets/map/lpr_day_up_selected.svg'
import lprDayDown from '@/assets/map/lpr_day_down.svg'
import lprDayDownSelected from '@/assets/map/lpr_day_down_selected.svg'
import lprNightUp from '@/assets/map/lpr_night_up.svg'
import lprNightUpSelected from '@/assets/map/lpr_night_up_selected.svg'
import lprNightDown from '@/assets/map/lpr_night_down.svg'
import lprNightDownSelected from '@/assets/map/lpr_night_down_selected.svg'

import sensorUp from '@/assets/map/sensor_up.svg'
import sensorUpSelected from '@/assets/map/sensor_up_selected.svg'
import sensorDown from '@/assets/map/sensor_down.svg'
import sensorDownSelected from '@/assets/map/sensor_down_selected.svg'

import ambulanciaUp from '@/assets/map/ambulancia_up.svg'
import ambulanciaUpSelected from '@/assets/map/ambulancia_up_selected.svg'
import ambulanciaDown from '@/assets/map/ambulancia_down.svg'
import ambulanciaDownSelected from '@/assets/map/ambulancia_down_selected.svg'
import policiaMunicipalUp from '@/assets/map/policia_municipal_up.svg'
import policiaMunicipalUpSelected from '@/assets/map/policia_municipal_up_selected.svg'
import policiaMunicipalDown from '@/assets/map/policia_municipal_down.svg'
import policiaMunicipalDownSelected from '@/assets/map/policia_municipal_down_selected.svg'
import patrullaMunicipalUp from '@/assets/map/patrulla_municipal_up.svg'
import patrullaMunicipalUpSelected from '@/assets/map/patrulla_municipal_up_selected.svg'
import patrullaMunicipalDown from '@/assets/map/patrulla_municipal_down.svg'
import patrullaMunicipalDownSelected from '@/assets/map/patrulla_municipal_down_selected.svg'
import policiaEstatalUp from '@/assets/map/policia_estatal_up.svg'
import policiaEstatalUpSelected from '@/assets/map/policia_estatal_up_selected.svg'
import policiaEstatalDown from '@/assets/map/policia_estatal_down.svg'
import policiaEstatalDownSelected from '@/assets/map/policia_estatal_down_selected.svg'
import policiaMinisterialUp from '@/assets/map/policia_ministerial_up.svg'
import policiaMinisterialUpSelected from '@/assets/map/policia_ministerial_up_selected.svg'
import policiaMinisterialDown from '@/assets/map/policia_ministerial_down.svg'
import policiaMinisterialDownSelected from '@/assets/map/policia_ministerial_down_selected.svg'
import patrullaMinisterialUp from '@/assets/map/patrulla_ministerial_up.svg'
import patrullaMinisterialUpSelected from '@/assets/map/patrulla_ministerial_up_selected.svg'
import patrullaMinisterialDown from '@/assets/map/patrulla_ministerial_down.svg'
import patrullaMinisterialDownSelected from '@/assets/map/patrulla_ministerial_down_selected.svg'
import patrullaPcivilUp from '@/assets/map/patrulla_pcivil_up.svg'
import patrullaPcivilUpSelected from '@/assets/map/patrulla_pcivil_up_selected.svg'
import patrullaPcivilDown from '@/assets/map/patrulla_pcivil_down.svg'
import patrullaPcivilDownSelected from '@/assets/map/patrulla_pcivil_down_selected.svg'
import patrullaEstatalUp from '@/assets/map/patrulla_estatal_up.svg'
import patrullaEstatalUpSelected from '@/assets/map/patrulla_estatal_up_selected.svg'
import patrullaEstatalDown from '@/assets/map/patrulla_estatal_down.svg'
import patrullaEstatalDownSelected from '@/assets/map/patrulla_estatal_down_selected.svg'
import patrullaGnacionalUp from '@/assets/map/patrulla_gnacional_up.svg'
import patrullaGnacionalUpSelected from '@/assets/map/patrulla_gnacional_up_selected.svg'
import patrullaGnacionalDown from '@/assets/map/patrulla_gnacional_down.svg'
import patrullaGnacionalDownSelected from '@/assets/map/patrulla_gnacional_down_selected.svg'

import deviceUp from '@/assets/map/device_up.png'
import deviceDown from '@/assets/map/device_down.png'

import incidentProteccionCivil from '@/assets/map/incident_proteccion_civil.svg'
import incidentMedico from '@/assets/map/incident_medico.svg'
import incidentSeguridad from '@/assets/map/incident_seguridad.svg'
import incidentServiciosPublicos from '@/assets/map/incident_servicios_publicos.svg'
import incidentProteccionCivilSelected from '@/assets/map/incident_proteccion_civil_selected.svg'
import incidentMedicoSelected from '@/assets/map/incident_medico_selected.svg'
import incidentSeguridadSelected from '@/assets/map/incident_seguridad_selected.svg'
import incidentServiciosPublicosSelected from '@/assets/map/incident_servicios_publicos_selected.svg'
import sosIncident from '@/assets/map/sos.png'
import onstarIncident from '@/assets/map/onstar.png'
import incident from '@/assets/map/incident.png'

export default {
  camera: {
    day: {
      up: cameraUp,
      up_selected: cameraUpSelected,
      down: cameraDown,
      down_selected: cameraDownSelected,
    },
    night: {
      up: cameraUp,
      up_selected: cameraUpSelected,
      down: cameraDown,
      down_selected: cameraDownSelected,
    },
    offsetY: -56,
    offsetX: -24
  },
  bus: {
    day: {
      up: busDayUp,
      up_selected: busDayUpSelected,
      down: busDayDown,
      down_selected: busDayDownSelected,
    },
    night: {
      up: busNightUp,
      up_selected: busNightUpSelected,
      down: busNightDown,
      down_selected: busNightDownSelected,
    },
    offsetY: -56,
    offsetX: -24
  },
  lpr: {
    day: {
      up: lprDayUp,
      up_selected: lprDayUpSeleted,
      down: lprDayDown,
      down_selected: lprDayDownSelected,
    },
    night: {
      up: lprNightUp,
      up_selected: lprNightUpSelected,
      down: lprNightDown,
      down_selected: lprNightDownSelected,
    },
    scale: 1,
    offsetY: -56,
    offsetX: -24
  },
  device: {
    municipal: {
      up: patrullaMunicipalUp,
      up_selected: patrullaMunicipalUpSelected,
      down: patrullaMunicipalDown,
      down_selected: patrullaMunicipalDownSelected,
      scale: 1,
      offsetY: -56,
      offsetX: -24
    },
    municipalp: {
      up: policiaMunicipalUp,
      up_selected: policiaMunicipalUpSelected,
      down: policiaMunicipalDown,
      down_selected: policiaMunicipalDownSelected,
      scale: 1,
      offsetY: -56,
      offsetX: -24
    },
    estatalp: {
      up: policiaEstatalUp,
      up_selected: policiaEstatalUpSelected,
      down: policiaEstatalDown,
      down_selected: policiaEstatalDownSelected,
      scale: 1,
      offsetY: -56,
      offsetX: -24
    },
    ministerial: {
      up: patrullaMinisterialUp,
      up_selected: patrullaMinisterialUpSelected,
      down: patrullaMinisterialDown,
      down_selected: patrullaMinisterialDownSelected,
      scale: 1,
      offsetY: -56,
      offsetX: -24
    },
    ministerialp: {
      up: policiaMinisterialUp,
      up_selected: policiaMinisterialUpSelected,
      down: policiaMinisterialDown,
      down_selected: policiaMinisterialDownSelected,
      scale: 1,
      offsetY: -56,
      offsetX: -24
    },
    ambulancia: {
      up: ambulanciaUp,
      up_selected: ambulanciaUpSelected,
      down: ambulanciaDown,
      down_selected: ambulanciaDownSelected,
      scale: 1,
      offsetY: -56,
      offsetX: -24
    },
    car: {
      up: patrullaEstatalUp,
      up_selected: patrullaEstatalUpSelected,
      down: patrullaEstatalDown,
      down_selected: patrullaEstatalDownSelected,
      scale: 1,
      offsetY: -56,
      offsetX: -24
    },
    arrow: {
      up: deviceUp,
      up_selected: deviceUp,
      down: deviceDown,
      down_selected: deviceDown,
      scale: 1,
      offsetY: -32,
      offsetX: -15
    },
    pcivil: {
      up: patrullaPcivilUp,
      up_selected: patrullaPcivilUpSelected,
      down: patrullaPcivilDown,
      down_selected: patrullaPcivilDownSelected,
      scale: 1,
      offsetY: -56,
      offsetX: -24
    },
    bronce: {
      up: deviceUp,
      up_selected: deviceUp,
      down: deviceDown,
      down_selected: deviceDown,
      scale: 1,
      offsetY: -32,
      offsetX: -15
    },
    guardia_nacional: {
      up: patrullaGnacionalUp,
      up_selected: patrullaGnacionalUpSelected,
      down: patrullaGnacionalDown,
      down_selected: patrullaGnacionalDownSelected,
      scale: 1,
      offsetY: -56,
      offsetX: -24
    },
    default: {
      up: deviceUp,
      up_selected: deviceUp,
      down: deviceDown,
      down_selected: deviceDown,
      scale: 1,
      offsetY: -32,
      offsetX: -15
    },
  },
  incident: {
    seguridad: incidentSeguridad,
    proteccion_civil: incidentProteccionCivil,
    medico: incidentMedico,
    servicios_publicos: incidentServiciosPublicos,
    seguridad_selected: incidentSeguridadSelected,
    proteccion_civil_selected: incidentProteccionCivilSelected,
    medico_selected: incidentMedicoSelected,
    servicios_publicos_selected: incidentServiciosPublicosSelected,
    default: incident,
    sos: sosIncident,
    uber: sosIncident,
    onstar: onstarIncident,
    scale: 1,
    offsetY: -56,
    offsetX: -24
  },
  sensor: {
    up: sensorUp,
    up_selected: sensorUpSelected,
    down: sensorDown,
    down_selected: sensorDownSelected,
    alert: sensorDown,
    alert_selected: sensorDownSelected,
    reported: sensorDown,
    reported_selected: sensorDownSelected,
    scale: 1,
    offsetY: -56,
    offsetX: -24
  },
  deviceDown,
  deviceUp
}
